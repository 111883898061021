@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --dark: #061F30;
    --primary: #113B57;
    --primary-dark: #12344A;
    --primary-light: #1b4460;
    --accent: #FFCD74;
    --secondary: #94B9D1;
    --light: #ECEADE;
    --light-2: #A2A2A3;
}

html,
body {
    min-height: 100vh;
}

input,
textarea {
    padding: 0.5rem;
    font-size: 1.1rem;
    background-color: var(--dark);
    border-radius: 0.5rem;
    outline: solid 2px transparent;
    transition: outline-width 0s, outline-color 0.1s;
}
input:focus,
textarea:focus {
    outline: solid 2px var(--light-2);
}
input._is-invalid,
textarea._is-invalid {
    outline: solid 2px #f04e4e;
}

label {
    color: var(--light-2);
    font-size: 1.25rem;
}

button,
button[type='submit'] {
    padding: 0.5rem 1.5rem;
    background-color: var(--dark);
    font-size: 1.1rem;
    border-radius: 0.25rem;
    transition: background-color 0.1s;
}
button:hover,
button[type='submit']:hover {
    background-color: var(--primary-light);
}
button:active,
button[type='submit']:active {
    background-color: var(--primary-dark);
}