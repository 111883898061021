*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: IBM Plex Sans, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-bottom-0 {
  bottom: 0;
}

.-bottom-0\.5 {
  bottom: -.125rem;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.z-50 {
  z-index: 50;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.-mt-0 {
  margin-top: 0;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-16 {
  height: 4rem;
}

.h-32 {
  height: 8rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[0\.2rem\] {
  height: .2rem;
}

.h-full {
  height: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-\[0\.2rem\] {
  width: .2rem;
}

.w-full {
  width: 100%;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.grow {
  flex-grow: 1;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-\[min-content\,_1fr\] {
  grid-template-columns: min-content 1fr;
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.justify-self-center {
  justify-self: center;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border-4 {
  border-width: 4px;
}

.border-b-\[1rem\] {
  border-bottom-width: 1rem;
}

.border-accent {
  --tw-border-opacity: 1;
  border-color: rgb(255 205 116 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(17 59 87 / var(--tw-border-opacity));
}

.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(6 31 48 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(17 59 87 / var(--tw-bg-opacity));
}

.bg-primary-dark\/50 {
  background-color: #12344a80;
}

.bg-primary\/80 {
  background-color: #113b57cc;
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(148 185 209 / var(--tw-bg-opacity));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.from-dark\/0 {
  --tw-gradient-from: #061f3000 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #061f3000 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-dark\/10 {
  --tw-gradient-from: #061f301a var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #061f3000 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-primary {
  --tw-gradient-from: #113b57 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #113b5700 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-dark\/50 {
  --tw-gradient-to: #061f3080 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-dark\/90 {
  --tw-gradient-to: #061f30e6 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-primary\/20 {
  --tw-gradient-to: #113b5733 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.bg-\[size\:3\.5rem\] {
  background-size: 3.5rem;
}

.bg-repeat {
  background-repeat: repeat;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-28 {
  padding-top: 7rem;
}

.text-right {
  text-align: right;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-\[2rem\] {
  font-size: 2rem;
}

.text-\[4rem\] {
  font-size: 4rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-none {
  line-height: 1;
}

.text-accent {
  --tw-text-opacity: 1;
  color: rgb(255 205 116 / var(--tw-text-opacity));
}

.text-light {
  --tw-text-opacity: 1;
  color: rgb(236 234 222 / var(--tw-text-opacity));
}

.text-light-2 {
  --tw-text-opacity: 1;
  color: rgb(162 162 163 / var(--tw-text-opacity));
}

.text-secondary {
  --tw-text-opacity: 1;
  color: rgb(148 185 209 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

:root {
  --dark: #061f30;
  --primary: #113b57;
  --primary-dark: #12344a;
  --primary-light: #1b4460;
  --accent: #ffcd74;
  --secondary: #94b9d1;
  --light: #eceade;
  --light-2: #a2a2a3;
}

html, body {
  min-height: 100vh;
}

input, textarea {
  background-color: var(--dark);
  border-radius: .5rem;
  outline: 2px solid #0000;
  padding: .5rem;
  font-size: 1.1rem;
  transition: outline-width, outline-color .1s;
}

input:focus, textarea:focus {
  outline: solid 2px var(--light-2);
}

input._is-invalid, textarea._is-invalid {
  outline: 2px solid #f04e4e;
}

label {
  color: var(--light-2);
  font-size: 1.25rem;
}

button, button[type="submit"] {
  background-color: var(--dark);
  border-radius: .25rem;
  padding: .5rem 1.5rem;
  font-size: 1.1rem;
  transition: background-color .1s;
}

button:hover, button[type="submit"]:hover {
  background-color: var(--primary-light);
}

button:active, button[type="submit"]:active {
  background-color: var(--primary-dark);
}

.hover\:text-light:hover {
  --tw-text-opacity: 1;
  color: rgb(236 234 222 / var(--tw-text-opacity));
}

.group:hover .group-hover\:border-light {
  --tw-border-opacity: 1;
  border-color: rgb(236 234 222 / var(--tw-border-opacity));
}

@media (min-width: 768px) {
  .md\:order-3 {
    order: 3;
  }

  .md\:-mr-14 {
    margin-right: -3.5rem;
  }

  .md\:ml-1 {
    margin-left: .25rem;
  }

  .md\:ml-1\.5 {
    margin-left: .375rem;
  }

  .md\:mr-14 {
    margin-right: 3.5rem;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:inline {
    display: inline;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:grow {
    flex-grow: 1;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-\[2fr\,_1fr\] {
    grid-template-columns: 2fr 1fr;
  }

  .md\:grid-cols-\[3fr\,_1fr\] {
    grid-template-columns: 3fr 1fr;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-y-3 {
    row-gap: .75rem;
  }

  .md\:bg-\[size\:4\.5rem\] {
    background-size: 4.5rem;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:p-20 {
    padding: 5rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:pt-36 {
    padding-top: 9rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-\[2\.5rem\] {
    font-size: 2.5rem;
  }

  .md\:text-\[5rem\] {
    font-size: 5rem;
  }

  .md\:opacity-0 {
    opacity: 0;
  }
}

@media (min-width: 1024px) {
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .xl\:bg-\[size\:5\.5rem\] {
    background-size: 5.5rem;
  }
}

/*# sourceMappingURL=index.d092da41.css.map */
